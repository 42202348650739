import React from "react";
import logo from '../logoDelVientoALosAndes2.svg';

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top shadow-5-strong">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/#page-top" data-target="#about">
            <img src={logo} alt="Snow" width={"80px"} height={"80px"} />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/#about" data-target="#about" className="page-scroll">
                Inscripción
              </a>
            </li>
            <li>
              <a href="/#features" data-target="#features" className="page-scroll">
                Documentos
              </a>
            </li>
            <li>
              <a href="/#jersey" data-target="#jersey" className="page-scroll">
                Jersey
              </a>
            </li>
            <li>
              <a href="/#sponsor" data-target="#sponsor" className="page-scroll">
                Sponsors
              </a>
            </li>
            <li>
              <a href="/#gallery" data-target="#gallery" className="page-scroll">
                Galeria
              </a>
            </li>
            <li>
              <a href="/maps" data-target="#maps" className="page-scroll">
                Etapas
              </a>
            </li>
            <li>
              <a href="/#contact" data-target="#contact" className="page-scroll">
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
