import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Maps } from "./components/maps";
import { Jersey } from "./components/jersey";
import { Sponsor } from "./components/sponsor"
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function App() {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  // function used to change from "/map" to "/#anchor"
  const { pathname, hash, key } = window.location;
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);
  // end function used to change from "/map" to "/#anchor"

  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="/" element={<MainContent landingPageData={landingPageData} />} />
          <Route path="/maps" element={<MapsPage landingPageData={landingPageData} />} />
        </Routes>
      </div>
    </Router>
  );
}

function MainContent({ landingPageData }) {
  return (
    <div>
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Features data={landingPageData.Features} />
      <Jersey data={landingPageData.Jersey} />
      <Sponsor data={landingPageData.Sponsor} />
      <Gallery data={landingPageData.Gallery} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
}

function MapsPage({ landingPageData }) {
  return (
    <div>
      <Maps data={landingPageData.route} />
    </div>
  );
}

export default App;
