import React from "react";
import './jerseyStyle.css'

export const Jersey = (props) => {
    return (
        <div id="jersey" className="jersey">
            <div className="container">

                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="about-text">
                            <h2>Jersey oficial</h2>
                            <a href="https://www.instagram.com/explore/tags/rallydelvientoalosandes/" target="_blank">
                                <h3>#rallydelvientoalosandes</h3>
                            </a>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        {" "}
                        <img src="img/jersey/Jersey1.png" className="img-responsive" alt="" />{" "}
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        {" "}
                        <img src="img/jersey/sizeJersey.jpeg" className="img-responsive" alt="" />{" "}
                        <h5>Podés elegir talles en tu inscripción solo en el mes de diciembre.</h5>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        {" "}
                        <img src="img/jersey/Jersey2.png" className="img-responsive" alt="" />{" "}
                    </div>
                </div>

            </div>
        </div>
    );
};