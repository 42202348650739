import React from "react";
import baguetteBox from 'baguettebox.js';

export const Gallery = (props) => {
  baguetteBox.run('.gallery');
  return (

    <div id="gallery" className="text-center">
      <br/>
      <div className="section-title">
        <h2>Galería</h2>
      </div>

      <div class="gallery">

        <a href="img/rally/lagunaVarvarcoTapia.jpeg">
          <img src="img/rally/lagunaVarvarcoTapia.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/trabajandoenequipo.jpeg">
          <img src="img/rally/trabajandoenequipo.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/2.000msnm.jpeg">
          <img src="img/rally/2.000msnm.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/acreditación2.jpg">
          <img src="img/rally/acreditación2.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/asistenciaAlCiclista.jpg">
          <img src="img/rally/asistenciaAlCiclista.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/campamentoenAtreuco.jpg">
          <img src="img/rally/campamentoenAtreuco.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/campamentoEnAtreuco2023.jpg">
          <img src="img/rally/campamentoEnAtreuco2023.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/campamentoyasadolagunaVarvarcoTapia.jpeg">
          <img src="img/rally/campamentoyasadolagunaVarvarcoTapia.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/campeonas2023.jpeg">
          <img src="img/rally/campeonas2023.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/chivitosypremiación.jpeg">
          <img src="img/rally/chivitosypremiación.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/crucedeaguastermales2aetapa.jpeg">
          <img src="img/rally/crucedeaguastermales2aetapa.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/desafiandolamontaña.jpg">
          <img src="img/rally/desafiandolamontaña.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/elasadodelaúltimanoche.jpg">
          <img src="img/rally/elasadodelaúltimanoche.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/elcieloa2000msnm.jpeg">
          <img src="img/rally/elcieloa2000msnm.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/finaldelesfuerzobanderaacuadros.jpg">
          <img src="img/rally/finaldelesfuerzobanderaacuadros.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/final3aetapa.jpeg">
          <img src="img/rally/final3aetapa.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/lafelicidaddelfinal.jpg">
          <img src="img/rally/lafelicidaddelfinal.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/lagunaVarvarcoTapia(2).jpeg">
          <img src="img/rally/lagunaVarvarcoTapia(2).jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/largadaenAtreuco.jpg">
          <img src="img/rally/largadaenAtreuco.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/largadaenHuinganco.jpg">
          <img src="img/rally/largadaenHuinganco.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/latrepadadelA°Atreuco.jpeg">
          <img src="img/rally/latrepadadelA°Atreuco.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/llegada3aetapa.jpg">
          <img src="img/rally/llegada3aetapa.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/maravillosonorteneuquino.jpg">
          <img src="img/rally/maravillosonorteneuquino.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/nuestrosarrieros(2).jpeg">
          <img src="img/rally/nuestrosarrieros(2).jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/nuestrosarrieros(3).jpeg">
          <img src="img/rally/nuestrosarrieros(3).jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/nuestrosarrieros.jpeg">
          <img src="img/rally/nuestrosarrieros.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/paisajesincreibles.jpg">
          <img src="img/rally/paisajesincreibles.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/porestoschivitosvaleelesfuerzo.jpeg">
          <img src="img/rally/porestoschivitosvaleelesfuerzo.jpeg" className="imgGallery"></img>
        </a>
        <a href="img/rally/rumboalvolcánDomuyo.jpg">
          <img src="img/rally/rumboalvolcánDomuyo.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/somoselnorteneuquino.jpg">
          <img src="img/rally/somoselnorteneuquino.jpg" className="imgGallery"></img>
        </a>
        <a href="img/rally/Va.AguasCalientes.jpg">
          <img src="img/rally/Va.AguasCalientes.jpg" className="imgGallery"></img>
        </a>


        {/*
        
        {props.data ? props.data.map((d, i) => (
          <a href={d.largeImage} >
            <img src={d.largeImage} class="imgGallery"></img>
          </a>
        )) : "Loading..."}

*/}
      </div>
    </div>
  )
}