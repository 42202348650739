import React, { useRef, useEffect, useState } from 'react';
import { ElevationProfileControl } from "@maptiler/elevation-profile-control";
import "@maptiler/sdk/dist/maptiler-sdk.css";
import './map.css';
import * as maptilersdk from '@maptiler/sdk';

export const MapAndElevation = (props) => {
    const mapContainer = useRef(null);
    const profileContainer = useRef(null);
    const map = useRef(null);
    const marker = useRef(null);
    const epc = useRef(null);
    const varvarcoNqn = { lng: -70.620229, lat: -36.621023 };
    const [zoom] = useState(8);
    maptilersdk.config.apiKey = 'sbyASJux2At803w0FDod';

    useEffect(() => {
        if (map.current || epc.current || marker.current) return; // stops map from initializing more than once

        map.current = new maptilersdk.Map({
            container: mapContainer.current,
            style: maptilersdk.MapStyle.OUTDOOR.DARK,
            center: [varvarcoNqn.lng, varvarcoNqn.lat],
            zoom: zoom
        });

        map.current.on('load', () => {
            // esto agrega las lineas al mapa

            map.current.addSource('route' + props.data2.id, {
                type: 'geojson',
                data: props.data
            });

            map.current.addLayer({
                id: 'route' + props.data2.id,
                type: 'line',
                source: 'route' + props.data2.id,
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#e11',
                    'line-width': 4
                }
            });

            // maptilersdk es el punto de posicièon en el mapa
            marker.current = new maptilersdk.Marker()
                .setLngLat([0, 0])
                .addTo(map.current);

            // Create an instance (with the customize options)
            epc.current = new ElevationProfileControl({
                container: profileContainer.current,
                visible: true,
                showButton: false,
                labelColor: "#FFF6",
                //labelColor: "#608dfd",
                elevationGridColor: "#FFF1",
                tooltipTextColor: "#000b",
                tooltipBackgroundColor: "#eeea",
                profileLineColor: "#608dfd",
                profileBackgroundColor: "#608dfd11",
                crosshairColor: "#66f5",
                displayTooltip: true,
                unit: "metric",
                onMove: (data) => {
                    marker.current.setLngLat(data.position);
                },
            });

            // Add it to your map
            map.current.addControl(epc.current);
            epc.current.setData(props.data);
        });

        // Cleanup function para asegurar que los componentes se desmonten correctamente
        return () => {
            if (map.current) {
                map.current.remove();
                map.current = null;
            }
            if (epc.current) {
                epc.current.remove();
                epc.current = null;
            }
            if (marker.current) {
                marker.current.remove();
                marker.current = null;
            }
        };
    }, [varvarcoNqn.lng, varvarcoNqn.lat, zoom]);

    return (
        <div className="parent-container">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6 text-left">
                        <div>
                            <h3>Etapa {props.data2.etapa} </h3>
                        </div >
                        <div ref={profileContainer} />
                        <div class="data-block">
                            <div class="data-bit">{props.data2.distance}</div>
                            <span class="data-label">Distancia</span>
                        </div>
                        <div class="data-block">
                            <div class="data-bit">{props.data2.totalAscent}</div>
                            <span class="data-label">Ascenso total</span>
                        </div>
                        <div class="data-block">
                            <div class="data-bit">{props.data2.totalDescent}</div>
                            <span class="data-label">Descenso total</span>
                        </div>
                    </div>
                    <div ref={mapContainer} className="col-xs-12 col-md-6 mapp" />
                </div >
            </div >
        </div >
    );
}
