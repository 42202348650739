import React from "react";
import YouTube from "react-youtube";

class VideoBackground extends React.Component {
  render() {
    const { className } = this.props;
    const opts = {
      /*width: 1520,*/
      /*height: 700,*/
      playerVars: {
        mute: 1,
        autoplay: 1,
        loop: 1,
        controls: 0,
        modestbranding: 1,
        showinfo: 0,
        playlist: "YvEir9POn7U", // (https://www.youtube.com/watch?v=YvEir9POn7U&ab_channel=DemisGerometta)
      },
    };

    return (
      <div className={className}>
        <YouTube videoId="YvEir9POn7U" opts={opts} />
      </div>
    );
  }
}

export default VideoBackground;