import React from "react";
// import bikeConstuction from '../bikeConstuction.svg';
import { MapAndElevation } from "./mapAndElevation";
import route1 from '../components/routes/etapa1.geojson';
import route2 from '../components/routes/etapa2.geojson';
import route3 from '../components/routes/etapa3.geojson';

const extraData = {
  1: {
    "id": "ssvvjsjkvn",
    "etapa": "1: Huinganco - Varvarco",
    "distance": "52,33 km",
    "totalAscent": "1.132 m",
    "totalDescent": "1.047 m"
  },
  2: {
    "id": "bgfñbmfgl",
    "etapa": "2: Varvarco - Los Cerrillos",
    "distance": "72,58 km",
    "totalAscent": "1.684 m",
    "totalDescent": "1.011 m"
  },
  3: {
    "id": "ockopwv",
    "etapa": "3: Los Cerrillos - Huinganco",
    "distance": "124,89 km",
    "totalAscent": "1.588 m",
    "totalDescent": "2.150 m"
  }
};

export const Maps = (props) => {
  return (
    <div id="maps" className="text-center">
      <div className="imagemaps">
        <div className="overlay">
          <MapAndElevation data={route1} data2={extraData[1]} />
          <MapAndElevation data={route2} data2={extraData[2]}/>
          <MapAndElevation data={route3} data2={extraData[3]}/>
        </div>
      </div>
    </div>
  );
};
