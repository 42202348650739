import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  user_name: "",
  user_email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ user_name, user_email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(user_name, user_email, message);
    emailjs.sendForm("service_thah0ld", "template_bh9ddfg", e.target, "_tc4qFr-jml_sgIba")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          alert("Mensaje enviado correctamente, chequeá el correo.");

          // clean inputs
          e.target.name.value = ""
          e.target.email.value = ""
          e.target.message.value = ""
        },
        (error) => {
          console.log(error.text);
          alert("No se pudo entregar el mensaje.");
        }
      );

  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contactate</h2>
                {/* Get In Touch */}
                <p>
                  Complete el siguiente formulario para enviarnos un correo electrónico y nos comunicaremos con usted lo antes posible.
                  {/* Please fill out the form below to send us an email and we will get back to you as soon as possible. */}
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="user_name"
                        className="form-control"
                        placeholder="Nombre"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="user_email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensaje"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar Mensaje
                  {/* Send Message */}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Información de contacto</h3>
              {/* Contact Info */}
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Dirección {/* Address */}
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <a href={props.data ? props.data.linkWhatsapp : "/" } target="_blank">
                <p>
                  <span>
                    <i className="fa fa-whatsapp"></i> Whatsapp {/* Phone  */}
                  </span>{" "}
                  {props.data ? props.data.whatsapp : "loading"}
                </p>
              </a>
            </div>
            <div className="contact-item">
              <a href={props.data ? props.data.linkWhatsapp2 : "/" } target="_blank">
                <p>
                  <span>
                    <i className="fa fa-whatsapp"></i> Whatsapp 2{/* Phone  */}
                  </span>{" "}
                  {props.data ? props.data.whatsapp2 : "loading"}
                </p>
              </a>
            </div>
            <div className="contact-item">
              <a href={props.data ? props.data.mailto : "/" } target="_blank">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </a>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/" } target="_blank">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"} target="_blank">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.linkWhatsapp : "/"} target="_blank">
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Rally mtb Del Viento a los Andes. Por{" "}
            <a href='mailto: demisgero22@gmail.com' target="_blank">
              Demis Gerometta <i className="fa fa-regular fa-envelope"></i>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
